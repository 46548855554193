<template>
  <div class="Scales bg-white">
    <Row class="px-1" :gutter="16">
      <i-col span="7" class="align-justify">
        <Form ref="formValidate" :model="task">
          <Row :gutter="32">
            <i-col span="24">
              <FormItem label="任务名称" required label-position="top">
                <Input
                  size="large"
                  v-model="task.taskName"
                  maxlength="15"
                  show-word-limit
                  placeholder="请输入任务名称"
                />
              </FormItem>
            </i-col>
          </Row>
          <FormItem label="任务说明" required label-position="top">
            <Input
              v-model="task.taskDesc"
              maxlength="15"
              size="large"
              show-word-limit
              placeholder="请输入任务说明"
            />
          </FormItem>
          <Row :gutter="32">
            <i-col span="24">
              <FormItem label="选择量表" required label-position="top">
                <Table
                  :border="false"
                  :show-header="false"
                  no-data-text="暂未选择"
                  :columns="checkScaleColumns"
                  :data="checkScaleData"
                  style="width:100%"
                >
                  <div slot-scope="{row}" slot="action">
                    <Button
                      type="primary"
                      size="small"
                      icon="md-remove-circle"
                      @click="removeScale(row)"
                    ></Button>
                  </div>
                </Table>
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="32">
            <i-col span="24">
              <FormItem label="结束时间" required label-position="top">
                <DatePicker
                  size="large"
                  :options="disabledDate"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm"
                  v-model="task.taskDay"
                  placeholder="选择结束时间"
                  style="display: block"
                ></DatePicker>
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="32" v-if="task.r_type == 1">
            <i-col span="24">
              <FormItem label="选择用户" required label-position="top">
                <Table
                  :border="false"
                  :show-header="false"
                  no-data-text="暂未选择"
                  :columns="checkUserColumns"
                  :data="checkUserData"
                  style="width:100%"
                >
                  <div slot-scope="{row}" slot="action">
                    <Button
                      type="primary"
                      size="small"
                      icon="md-remove-circle"
                      @click="removeUser(row)"
                    ></Button>
                  </div>
                </Table>
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="32" v-if="task.r_type == 2">
            <i-col span="24">
              <FormItem label="选择所属单位/部门" label-position="top">
                <Cascader
                  v-model="task.unitId"
                  :data="companyData"
                  @on-change="checkCascader"
                  change-on-select
                ></Cascader>
              </FormItem>
            </i-col>
          </Row>
        </Form>
        <div class="demo-drawer-footer" style="float:left">
          <Button type="primary" @click="releaseTask">发布</Button>
        </div>
      </i-col>
      <i-col span="7" class="align-right">
        <i-col span="24" class="align-justify">
          <i-input
            v-model="scalekeyword"
            style="width: 15rem"
            class="el-inline mr-1"
            placeholder="输入量表名称进行查询"
          ></i-input>
          <Button type="primary" class="mr-1" @click="searchScale">搜索量表</Button>
        </i-col>
        <i-col span="24">
          <div
            style="text-align:center;margin-top:30px;margin-bottom:30px"
            v-if="scaleData.length == 0"
          >
            <img src="../assets/nodata.png" />
            <h3>没有找到匹配的数据</h3>
          </div>
          <List :loading="scalesearching" v-if="scaleData.length != 0">
            <ListItem v-for="(item,key) in scaleData" :key="key" :value="item.scaleId">
              <ListItemMeta :title="item.scaleName">
                <template slot="description">
                  <Poptip trigger="hover" word-wrap width="500">
                    <span slot="content" v-html="item.describe"></span>
                    <span style="color:#57a3f3;text-decoration:underline">量表简介</span>
                  </Poptip>
                </template>
              </ListItemMeta>
              <template slot="action">
                <li>
                  <Button type="primary" size="small" @click="selectScale(item)">选用</Button>
                </li>
              </template>
            </ListItem>
          </List>
          <Page
            ref="scalePagination"
            :total="scaleTotal"
            :page-size="scaleSize"
            @on-change="changeScalePage"
            @on-page-size-change="changeScaleSize"
            show-total
            class="p-1 align-right"
          />
        </i-col>
      </i-col>
      <i-col span="7" class="align-right">
        <i-col span="24" class="align-justify">
          <i-input
            v-model="userkeyword"
            style="width: 15rem"
            class="el-inline mr-1"
            placeholder="输入用户名、姓名等信息进行查询"
          ></i-input>
          <Button type="primary" class="mr-1" @click="searchUser">搜索用户</Button>
        </i-col>
        <i-col span="24">
          <div
            style="text-align:center;margin-top:30px;margin-bottom:30px"
            v-if="userData.length == 0"
          >
            <img src="../assets/nodata.png" />
            <h3>没有找到匹配的数据</h3>
          </div>
          <List :loading="usersearching" v-if="userData.length != 0">
            <ListItem v-for="(item,key) in userData" :key="key" :value="item.scaleId">
              <ListItemMeta :title="'姓名：' + item.realName">
                <template slot="description">
                  <span
                    class="item-desc"
                  >登录名：{{item.loginName}} 性别：{{getGender(item.gender)}} 年龄：{{getAge(item.birthday)}}</span>
                </template>
              </ListItemMeta>
              <template slot="action">
                <li>
                  <Button type="primary" size="small" @click="selectUser(item)">选用</Button>
                </li>
              </template>
            </ListItem>
          </List>
          <Page
            ref="userPagination"
            :total="userTotal"
            :page-size="userSize"
            @on-change="changeUserPage"
            @on-page-size-change="changeUserSize"
            show-total
            class="p-1 align-right"
          />
        </i-col>
      </i-col>
    </Row>
  </div>
</template>

<script>
export default {
  name: "ReleaseScaleTask",
  data() {
    return {
      disabledDate: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now();
        },
      },
      checkUserColumns: [
        {
          type: "index",
          title: "序号",
          align: "center",
          width: "40",
        },
        {
          title: "登录名",
          key: "loginName",
        },
        {
          title: "姓名",
          key: "realName",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: "40",
        },
      ],
      checkUserData: [],
      checkScaleColumns: [
        {
          type: "index",
          title: "序号",
          align: "center",
          width: "40",
        },
        {
          title: "量表名",
          key: "scaleName",
          tooltip: true,
          ellipsis: true,
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: "40",
        },
      ],
      checkScaleData: [],

      theight: window.innerHeight - 225,
      task: {
        r_type: 1,
        unitId: [],
      },
      userData: [],
      userColumns: [
        {
          title: "登录名",
          key: "loginName",
        },
        {
          title: "姓名",
          key: "realName",
        },
        {
          title: "性别",
          key: "gender",
          align: "center",
          render: (h, params) => {
            return h("span", this.myjs.getGender(params.row.gender));
          },
        },
        {
          title: "年龄",
          key: "age",
          align: "center",
          render: (h, params) => {
            let age = this.myjs.getAge(params.row.birthday, new Date());
            return h("span", age);
          },
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      usersearching: true,
      userSize: 10,
      userNum: 1,
      userkeyword: "",
      userTotal: 0,

      scaleData: [],
      scaleColumns: [
        {
          title: "量表名",
          key: "scaleName",
          tooltip: true,
          ellipsis: true,
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: "60",
        },
      ],
      scalesearching: true,
      scaleSize: 10,
      scaleNum: 1,
      scalekeyword: "",
      scaleTotal: 0,
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 225),
      false
    );
    this.getUser();
    this.getScale();
  },
  methods: {
    getGender(gender) {
      return this.myjs.getGender(gender);
    },
    getAge(age) {
      return this.myjs.getAge(age, new Date());
    },
    releaseTask() {
      if (this.task.taskName == undefined) {
        this.$Message.error("任务名称为必填项");
        return;
      }
      if (this.task.taskDesc == undefined) {
        this.$Message.error("任务说明为必填项");
        return;
      }
      if (this.checkScaleData.length <= 0) {
        this.$Message.error("至少选择一个量表");
        return;
      }
      if (this.task.taskDay == "") {
        this.$Message.error("任务结束时间为必选项");
        return;
      }
      if (this.checkUserData.length <= 0) {
        this.$Message.error("至少选择一个测试人员");
        return;
      }
      this.$Modal.confirm({
        title: "提示",
        content: "<p>确定发布?</p>",
        loading: true,
        onOk: () => {
          let adminid = localStorage.getItem("adminId");
          let users = "";
          this.checkUserData.forEach((item) => (users += item.userId + ","));
          let scales = "";
          this.checkScaleData.forEach((item) => (scales += item.scaleId + ","));
          console.log(
            this.task.taskName,
            this.task.taskDesc,
            this.task.taskDay,
            users,
            scales,
            adminid
          );
          this.$post("Task/SubmitAloneTask", {
            taskName: this.task.taskName,
            taskDesc: this.task.taskDesc,
            taskDay: this.task.taskDay,
            adminId: parseInt(adminid),
            users: users,
            scales: scales,
          }).then((res) => {
            if (res.data.code) {
              this.$Modal.remove();
              this.$Message.success(res.data.msg);
              this.$router.go(-1);
            } else {
              this.$Modal.remove();
              this.$Message.error(res.data.msg);
            }
          });
        },
        onCancel: () => {},
      });
    },
    removeUser(row) {
      this.checkUserData = this.checkUserData.filter(
        (item) => item.userId != row.userId
      );
    },
    selectUser(row) {
      if (
        this.checkUserData.find((value) => value.userId == row.userId) !=
        undefined
      ) {
        this.$Message.error("此项已选");
        return;
      }
      if (this.checkUserData.length >= 10) {
        this.$Message.warning("最多可选十个人");
        return;
      }
      this.checkUserData.push(row);
    },
    getUser() {
      this.usersearching = true;
      // 获取用户列表
      this.$get("Customer/GetCustomerList", {
        companyId: -1,
        gender: -1,
        keyword: this.userkeyword,
        statusCode: 1,
        pageSize: this.userSize,
        pageNum: this.userNum,
      }).then((Customer) => {
        this.userData = Customer.data.responseList;
        this.userTotal = Customer.data.total;
        this.usersearching = false;
      });
    },
    changeUserPage(e) {
      this.userNum = e;
      this.getUser();
    },
    changeUserSize(e) {
      this.userSize = e;
      this.getUser();
    },
    searchUser() {
      this.getUser();
    },

    removeScale(row) {
      this.checkScaleData = this.checkScaleData.filter(
        (item) => item.scaleId != row.scaleId
      );
    },
    selectScale(row) {
      if (
        this.checkScaleData.find((value) => value.scaleId == row.scaleId) !=
        undefined
      ) {
        this.$Message.error("此项已选");
        return;
      }
      this.checkScaleData.push(row);
    },
    getScale() {
      this.scalesearching = true;
      this.$get("Scale/GetScaleList", {
        requestName: "GetScaleList",
        typeId: -1,
        keyword: this.scalekeyword,
        statusCode: 1,
        pageSize: this.scaleSize,
        pageNum: this.scaleNum,
      }).then((scale) => {
        this.scaleTotal = scale.data.total;
        this.scaleData = scale.data.responseList;
        this.scalesearching = false;
      });
    },
    changeScalePage(e) {
      this.scaleNum = e;
      this.getScale();
    },
    changeScaleSize(e) {
      this.scaleSize = e;
      this.getScale();
    },
    searchScale() {
      this.getScale();
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.ivu-list-item-meta {
  text-align: left;
}
.item-desc {
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>